.persCardTitle {
  font-size: 14;
}

.cargosList {
  width: 100% !important;
}

.MuiFormLabel-root {
  color: "Blue" !important;
}

.modifyUsers {
  width: 100%;
}

.centered {
  text-align: center;
}

.margins {
  margin: 10px 10px 10px 10px !important;
  padding: 15px 15px 15px 30px !important;
}

.textAreaY {
  width: 800px !important;
}

.embedded-lang {
  display: flex;
  align-items: center;
  gap: 15px;
}
.embedded-lang select{
  height: 28px;
}
.embedded-lang h6{
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  margin: 0;
}

